import Menu from "../data/menu.json";
import isoWeek from "dayjs/plugin/isoWeek";

import { defineStore } from "pinia";
export const useMenuStore = defineStore("menu", () => {
  const data = ref(Menu.data.filter((x) => x.visible));
  const menuRefs = ref({});

  const weeks = ref(3);
  const dayjs = useDayjs();
  dayjs.extend(isoWeek);

  const buildNewReleaseLinks = () => {
    let links = [];
    const currentDate = dayjs();
    for (let i = 0; i < weeks.value; i++) {
      const releaseWeek = currentDate.subtract(i, "weeks");
      const weekNo = releaseWeek.isoWeek(); // Use Day.js to get the week number
      const releaseDateString = releaseWeek.format("YYYYMMDD");

      links.push({
        weekNo,
        releaseYear: releaseWeek.year(),
        Release: releaseDateString,
        label: `Order in week ${weekNo}/${releaseWeek.year()}`,
      });
    }
    return links;
  };
  const releaseLinks = computed(() => buildNewReleaseLinks());
  const addMenu = ({
    key,
    title,
    type,
    badgeValue,
    active,
    visible,
    authOnly,
    children = [],
  }) => {
    data.value.push({
      key,
      title,
      type,
      badgeValue,
      active,
      visible,
      children,
      authOnly,
    });
  };
  const addChildToMenu = (key) => {
    const children = ref([
      {
        path: "/products/search",
        key: "complete_overview",
        query: {
          attribute: JSON.stringify({
            status_preorder: { "status preorder": true },
          }),
        },
        title: "Complete overview",
        type: "link",
      },
    ]);
    children.value.push(
      ...releaseLinks.value.map((x) => {
        return {
          path: `/products/search`,
          key: x.label,
          title: x.label,
          type: "link",
          query: {
            release: {
              releaseWeek: x.weekNo,
              releaseYear: x.releaseYear,
              release: x.Release,
              // Release: x.Release,
            },
          },
          legacy: true,
        };
      })
    );

    const index = data.value.findIndex((x) => x.key === key);
    if (index >= 0) {
      data.value[index].children = children.value;
    }
  };
  const addChildToPromo = (key, children) => {
    const index = data.value.findIndex((x) => x.key === key);
    if (index >= 0) {
      data.value[index].children = children;
    }
  };


  const setMenuRefs = (key, value) => {
    menuRefs.value[key] = value;
  };

  return {
    data,
    addMenu,
    addChildToMenu,
    addChildToPromo,
    setMenuRefs,
    menuRefs
  };
});
